
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
  onUnmounted,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import Utils from "@/utils/Utils";
import { AnyARecord } from "dns";
import "./WavAudioEncoder.min";
import { AiuiService } from "@/services/aiuiservice";
import { getMicrophonePermission, isMobile } from "./AudioUtils";
import Header from "@/components/Header.vue";
import TaskCards from "@/components/ListCard/TaskCards.vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Setting from "@/rest/Setting";
import { nextTick } from "process";
import SystemUtils from "@/utils/SystemUtils";
import { TaskService } from "@/services/task/TaskService";
import { getInstance } from "@/services/selectexecutor/UserInfoByUserNameService";
import moment from "moment";
import { TextToSpeech } from "@ionic-native/text-to-speech";
import { isPlatform } from "@ionic/vue";
import { File } from "@ionic-native/file";
export default defineComponent({
  name: "AiuiSearchCondition",
  components: { Header, TaskCards, CardLoading },
  setup(props, context) {
    const route = useRoute(); // 返回当前路由位置。相当于在内部使用“$route” 获取路由传参
    const router = useRouter(); //返回路由器实例。相当于在内部使用“$router” 进行路由跳转
    //iconyuyinbobaoguanbi
    let btnIconRight: any = reactive({ data: ["iconyuyinbobao"] });
    // const btnSizeRight: any = reactive({ data: ["34px"] });
    let selectUserId = ref("");
    let flagCount = ref(0);
    let maxTime = 15;
    let taskService = new TaskService();
    let userService = getInstance();
    //----筛选条件字段--------------
    let searchPanelForm = ref({
      limit: 100,
      offset: 1,
      name: "", //任务名称
      priority: "", //优先级（多选）不传默认全部
      overdueStatus: "", //状态（单选）不传默认全部，1未逾期，2已逾期，3逾期完成
      isFinish: "", //是否完成 不传默认全部，0未完成，1已完成
      workUsers: "", //执行人
      agentStart: "",
      agentEnd: "",
      queryType: "task",
    });
    let isSVW = Setting.isSVW; //是否上汽环境，隐藏部分功能
    let voiceText = ref("按住说话");
    let currentUserName = ref("");
    let islist = ref(true);
    if (SystemUtils.loginUser.name)
      currentUserName.value = SystemUtils.loginUser.name;

    const searchQuestion = reactive({
      data: [
        {
          id: "1",
          name: "查询我今天的待办任务",
        },
        {
          id: "2",
          name: "查询刘玉梅需本周完成的任务",
        },
        {
          id: "3",
          name: "查询我延期完成的任务",
        },
      ],
    });

    const taskList = reactive({ data: new Array<any>() });
    let isclick = true;
    let startY: any = null;
    let startX: any = 0;
    let moveY: any = ref(0);
    let voiceStatus: any = ref(0);
    let animationIndex: any = ref(0);

    let captureCfg: any = null; // Capture configuration object
    let audioDataBuffer: any = new Array(); // Audio Buffer
    let objectURL: any = null; // For file URL; // Info/Debug
    // URL shim
    window.URL = window.URL || window.webkitURL;

    let repContent = ref(new Array());
    let fileUrl = "";
    let fileName = "";

    let chatArr = reactive({ data: new Array<any>() });

    const pushChatArr = (
      isWaited: boolean,
      question: any,
      answer: any,
      audioTime?: any
    ) => {
      let model = {
        isWaited: isWaited,
        question: question,
        answer: answer,
        userList: new Array<any>(),
        audioTime: audioTime,
      };
      if (audioTime && audioTime == "none") {
        chatArr.data.splice(chatArr.data.length - 1, 1, model);
      } else {
        chatArr.data.push(model);
        textToSpeech(answer);
      }
      //最新消息滚动到顶部
      scrollToTop();
    };
    const aiuiService = new AiuiService();
    // const { App } = Plugins;
    onMounted(() => {
      islist.value = false;
      if ("taskList" in route.params) {
        let taskParams = route.params.taskList + "";
        taskList.data = JSON.parse(taskParams);
      }
      // getListRequest((res: any) => {
      //   islist.value = false;
      //   taskList.data = res;
      // });

      // Make it possible to run the demo on desktop.
      if (!(<any>window).cordova) {
        console.log("Running on desktop!");
        onDeviceReady();
      } else {
        // For Cordova apps
        console.log("Running on device!");
        document.addEventListener("deviceready", onDeviceReady, false);
        // islist.value = false;
      }
    });
    //开始录制
    const touchstart = (e: any) => {
      moveY = 1;
      voiceStatus.value = 1;
      e.preventDefault();
      getInterval();
      voiceText.value = "松开识别";
      startY = e.changedTouches[0].pageY;
      startX = e.changedTouches[0].pageX;
      isclick = true;
      startCapture();
    };
    //音阶动画
    const animation = () => {
      let num = Math.ceil(Math.random() * 5);
      if (animationIndex.value > 1) {
        animationIndex.value = 1;
      }
      if (animationIndex.value % 2 == 0 && animationIndex.value > 0) {
        animationIndex.value -= num;
      } else {
        animationIndex.value += num;
      }
    };
    let animationInterval: any = null;

    const getInterval = () => {
      if (animationInterval == null) {
        animationInterval = setInterval(animation, 300);
      }
    };
    const textToSpeech = (answer: any) => {
      if (btnIconRight.data[0] == "iconyuyinbobao") {
        Utils.textTospeech(answer);
      }
    };
    const changeIon = () => {
      if (btnIconRight.data[0] == "iconyuyinbobao") {
        btnIconRight.data = ["iconyuyinbobaoguanbi"];
      } else {
        btnIconRight.data = ["iconyuyinbobao"];
      }
    };
    //结束说话 停止录制
    const touchend = () => {
      if (flagCount.value > maxTime) {
        return;
      }
      console.log("结束说话");
      // moveY = 0;
      if (animationInterval != null) {
        clearInterval(animationInterval);
        animationInterval = null;
      }
      voiceStatus.value = 0;
      voiceText.value = "按住说话";
      if (moveY < -30) {
        isclick = true;
      } else {
        isclick = false;
      }
      stopCapture(isclick);
    };
    const touchmove = (e: any) => {
      e.preventDefault();
      var moveEndY = e.changedTouches[0].pageY;
      moveY = moveEndY - startY;
      if (flagCount.value <= maxTime) {
        if (moveY < -30) {
          voiceStatus.value = 2;
          voiceText.value = "松开取消";
          isclick = true;
        } else {
          voiceStatus.value = 1;
          voiceText.value = "松开识别";
          isclick = false;
        }
      }
    };

    /**
     * Called continuously while AudioInput capture is running.
     */
    const onAudioInputCapture = (evt: any) => {
      try {
        if (evt && evt.data) {
          audioDataBuffer.push(...evt.data);
          if (flagCount.value >= maxTime) {
            //超过15s自动停止
            touchend();
          }
          flagCount.value++;
        }
      } catch (ex) {
        alert("onAudioInputCapture ex: " + ex);
      }
    };

    /**
     * Called when a plugin error happens.
     */
    const onAudioInputError = (error: any) => {
      alert("onAudioInputError event recieved: " + JSON.stringify(error));
    };

    /**
     * When cordova fires the deviceready event, we initialize everything needed for audio input.
     */
    const onDeviceReady = () => {
      if (
        (<any>window).cordova &&
        (<any>window).cordova.file &&
        (<any>window).audioinput
      ) {
        console.log("Use 'Start Capture' to begin...");
        // Subscribe to audioinput events
        (<any>window).addEventListener(
          "audioinput",
          onAudioInputCapture,
          false
        );
        (<any>window).addEventListener(
          "audioinputerror",
          onAudioInputError,
          false
        );

        // See utils.js
        getMicrophonePermission(
          () => {
            console.log("Microphone input starting...");
            console.log("Microphone input started!");
          },
          (deniedMsg: AnyARecord) => {
            console.log(deniedMsg);
          },
          (errorMsg: any) => {
            console.log(errorMsg);
          }
        );
      } else {
        console.log(
          "Missing: cordova-plugin-file or cordova-plugin-audioinput!"
        );
      }
    };

    /**
     * Start capturing audio.
     */
    const startCapture = () => {
      audioDataBuffer = new Array();
      flagCount.value = 0;
      objectURL = null;
      try {
        // let audioinput = (<any>window).audioinput;
        if (
          (<any>window).audioinput &&
          !(<any>window).audioinput.isCapturing()
        ) {
          captureCfg = {
            audioSourceType: 0,
            sampleRate: 16000,
            channels: 1,
            format: (<any>window).audioinput.FORMAT.PCM_16BIT,
          };

          // See utils.js
          getMicrophonePermission(
            () => {
              console.log("Microphone input starting...");
              (<any>window).audioinput.start(captureCfg);
              console.log("Microphone input started!");
              // Throw previously created audio
              if (objectURL) {
                URL.revokeObjectURL(objectURL);
              }
            },
            (deniedMsg: AnyARecord) => {
              console.log(deniedMsg);
            },
            (errorMsg: any) => {
              console.log(errorMsg);
            }
          );
        }
      } catch (e) {
        alert("startCapture exception: " + e);
      }
    };
    const fileSystemPath = isPlatform("ios")
      ? File.dataDirectory
      : File.externalRootDirectory;

    /**
     * Stop the capture, encode the captured audio to WAV, save it to a file and show file URL in UI.
     */

    const stopCapture = (isBlock?: boolean) => {
      try {
        if (isBlock == undefined) isBlock = false;
        if (
          (<any>window).audioinput &&
          (<any>window).audioinput.isCapturing()
        ) {
          if (isMobile.any() && (<any>window).audioinput) {
            setTimeout(() => {
              //延时结束录音
              (<any>window).audioinput.stop();
            }, 500);
            // (<any>window).audioinput.stop();
          }
          //取消搜索，终止执行
          if (isBlock) {
            return;
          }
          console.log("Encoding WAV...");
          let encoder = new (<any>window).WavAudioEncoder(
            (<any>window).audioinput.getCfg().sampleRate,
            (<any>window).audioinput.getCfg().channels
          );
          encoder.encode([audioDataBuffer]);

          console.log("Encoding WAV finished");

          let blob = encoder.finish("audio/wav");
          console.log("BLOB created");
          (<any>window).resolveLocalFileSystemURL(
           fileSystemPath,
            function (dir: any) {
              fileName = new Date().getTime() + ".wav";
              dir.getFile(fileName, { create: true }, function (file: any) {
                file.createWriter(
                  function (fileWriter: any) {
                    fileWriter.write(blob);
                    fileUrl = file.toURL();
                    console.log("fileUrl.value:*****" + fileUrl);
                    console.log("File created!");
                    pushChatArr(false, fileUrl, "", flagCount.value + "s ");

                    setTimeout(() => {
                      aiuiService
                        .aiuiSend({
                          filePath: fileUrl,
                          fileName: fileName,
                        })
                        .then((rep: any) => {
                          console.log("rep:******" + JSON.stringify(rep.data));
                          repContent.value = JSON.parse(rep.data).data;
                          if (repContent.value) {
                            // let iat = repContent.value.find((item) => item.sub == "iat"); // 识别内容
                            let nlp = repContent.value.find(
                              (item) => item.sub == "nlp"
                            ); //语义返回内容
                            let chatModel = {
                              isWaited: true,
                              question: "",
                              answer: "",
                              userList: new Array<any>(),
                            };
                            if (
                              nlp &&
                              nlp.intent &&
                              JSON.stringify(nlp.intent) != "{}"
                            ) {
                              chatModel.question = nlp.intent.text;
                              chatModel.answer = nlp.intent.answer.text;
                              //处理返回值 拼接查询参数
                              if (nlp.intent.data) {
                                let queryParms = nlp.intent.data.result;
                                if (queryParms) {
                                  let DomainClassinfo = "task";
                                  if (queryParms[0].DomainClassinfo) {
                                    DomainClassinfo = queryParms[0].DomainClassinfo.toLowerCase();
                                  }
                                  switch (DomainClassinfo) {
                                    case "task":
                                      console.log("任务视图=====");
                                      // 任务查询条件公共参数赋值  需要的直接从这里写  start******
                                      let uName = queryParms[0].UserName + "";
                                      let agentStart ="";
                                      let agentEnd ="";
                                      searchPanelForm.value.overdueStatus = queryParms[0]
                                        .overdueStatus
                                        ? queryParms[0].overdueStatus
                                        : "";
                                      searchPanelForm.value.isFinish = queryParms[0]
                                        .taskIsFinished
                                        ? queryParms[0].taskIsFinished
                                        : "0";
                                      if (queryParms[0].date) {
                                        //处理逻辑 时间格式
                                        let agentDate = queryParms[0].date + "";
                                        let suggestDatetime =
                                          queryParms[0].suggestDatetime + "";
                                        /*
                                        是否问的区间 
                                        2月3日到5日-{"datetime":"M02D03/M02D05","suggestDatetime":"2021-02-03/2021-02-05"}
                                        1月到4月——{"datetime":"M01/M04","suggestDatetime":"2021-01-03/2021-04-03"}
                                        */
                                        if (agentDate.indexOf("/") > -1) {
                                          let dateTimeArr = suggestDatetime.split(
                                            "/"
                                          );
                                          agentStart = dateTimeArr[0];
                                          agentEnd = dateTimeArr[1];
                                          /*2月3日到5日 {"datetime":"M02D03/M02D05","suggestDatetime":"2021-02-03/2021-02-05"} */
                                          if (agentDate.indexOf("D") > -1) {
                                            agentStart = agentStart;
                                            agentEnd = agentEnd;
                                          } else {
                                            /*  1月到4月——{"datetime":"M01/M04","suggestDatetime":"2021-01-03/2021-04-03"}*/
                                            agentStart = moment(agentStart)
                                              .startOf("month")
                                              .format("YYYY-MM-DD");
                                            agentEnd = moment(agentEnd)
                                              .endOf("month")
                                              .format("YYYY-MM-DD");
                                          }
                                        } else {
                                          /*
                                            7) 近7天—— {"datetime":"O-[7D]","suggestDatetime":"2021-02-24"}
                                            8)近5个月——{"datetime":"O-[5M]","suggestDatetime":"2020-10-03"}
                                            9)近2周——{"datetime":"O-[2W]","suggestDatetime":"2021-02-17"}
                                          */
                                          //查询的是近几天/几周/几月
                                          if (agentDate.indexOf("O") > -1) {
                                            agentStart = suggestDatetime;
                                            agentEnd = moment(
                                              new Date()
                                            ).format("YYYY-MM-DD");
                                          } else {
                                            /*
                                               1)本季度—— 年的第几个季度。{"datetime":"Q01","suggestDatetime":"2021-01-01"}
                                               */
                                            if (agentDate.indexOf("Q") > -1) {
                                              agentStart = moment(
                                                suggestDatetime
                                              )
                                                .startOf("quarter")
                                                .format("YYYY-MM-DD");
                                              agentEnd = moment(suggestDatetime)
                                                .endOf("quarter")
                                                .format("YYYY-MM-DD");
                                            } else {
                                              /*
                                                   2)本周—— 本年度的第几个周。 {"datetime":"Y2021W10","suggestDatetime":"2021-03-03"}
                                                  */
                                              if (agentDate.indexOf("W") > -1) {
                                                agentStart = moment(
                                                  suggestDatetime
                                                )
                                                  .startOf("isoWeek")
                                                  .format("YYYY-MM-DD");
                                                agentEnd = moment(
                                                  suggestDatetime
                                                )
                                                  .endOf("isoWeek")
                                                  .format("YYYY-MM-DD");
                                              } else {
                                                /*
                                                3)今天——{"datetime":"Y2021M03D03","suggestDatetime":"2021-03-03"}
                                                4)昨天——{"datetime":"Y2021M03D02","suggestDatetime":"2021-03-02"}
                                                  */
                                                agentStart = suggestDatetime;
                                                agentEnd = suggestDatetime;
                                              }
                                            }
                                          }
                                        }
                                      }
                                      searchPanelForm.value.agentStart = agentStart;
                                      searchPanelForm.value.agentEnd = agentEnd;
                                      // 任务查询条件公共参数赋值   end******
                                      if (uName.toLowerCase() == "my") {
                                        // 处理消息数组
                                        if (
                                          chatModel.question &&
                                          chatModel.answer
                                        ) {
                                          chatArr.data.splice(
                                            chatArr.data.length - 1,
                                            1,
                                            chatModel
                                          );
                                          //最新消息滚动到顶部
                                          scrollToTop();
                                        }
                                        islist.value = true;
                                        searchPanelForm.value.workUsers =
                                          SystemUtils.loginUser.id;
                                        taskList.data = [];
                                        getListRequest((res: any) => {
                                          islist.value = false;
                                          taskList.data = res;
                                          let answer =
                                            "为您查询到" +
                                            taskList.data.length +
                                            "条相关信息";
                                          pushChatArr(true, "", answer);
                                        });
                                      } else {
                                        //验证人名是否重复 调用接口
                                        getUserInfoByPinyin(
                                          uName,
                                          (userInfos: any) => {
                                            console.log(
                                              "验证是否有重名的人" +
                                                JSON.stringify(userInfos)
                                            );
                                            if (userInfos.length > 1) {
                                              chatModel.answer =
                                                "有" +
                                                userInfos.length +
                                                "个相似的用户，请选择您要查询的用户";
                                              //补充说明
                                              chatModel.userList = userInfos;
                                            } else if (userInfos.length == 1) {
                                              searchPanelForm.value.workUsers =
                                                userInfos[0].id;
                                              taskList.data = [];
                                              console.log(userInfos[0].id);
                                              islist.value = true;
                                              getListRequest((res: any) => {
                                                islist.value = false;
                                                taskList.data = res;
                                                let answer =
                                                  "为您查询到" +
                                                  taskList.data.length +
                                                  "条相关信息";
                                                pushChatArr(true, "", answer);
                                              });
                                            } else {
                                              taskList.data = [];
                                              chatModel.answer =
                                                "查询用户失败，未查询到此用户";
                                            }
                                            chatArr.data.splice(
                                              chatArr.data.length - 1,
                                              1,
                                              chatModel
                                            );
                                            textToSpeech(chatModel.answer);
                                            //最新消息滚动到顶部
                                            setTimeout(() => {
                                              scrollToTop();
                                            }, 1000);
                                          }
                                        );
                                      }
                                      break;
                                    case "project":
                                      //跳转路由
                                      Utils.presentToastSuccess("跳转项目详情");
                                      break;
                                    case "schedule":
                                      //跳转路由
                                      Utils.presentToastSuccess("跳转日程详情");
                                      break;
                                    default:
                                      break;
                                  }
                                } else {
                                  console.log(
                                    "queryParms 云处理拼接的data 为空"
                                  );
                                  // 处理消息数组
                                  if (chatModel.question && chatModel.answer) {
                                    chatArr.data.splice(
                                      chatArr.data.length - 1,
                                      1,
                                      chatModel
                                    );

                                    textToSpeech(chatModel.answer);
                                    //最新消息滚动到顶部
                                    scrollToTop();
                                  }
                                }
                              } else {
                                console.log("nlp.intent.data 为空");
                                // 处理消息数组
                                if (chatModel.question && chatModel.answer) {
                                  chatArr.data.splice(
                                    chatArr.data.length - 1,
                                    1,
                                    chatModel
                                  );
                                  textToSpeech(chatModel.answer);
                                  //最新消息滚动到顶部
                                  scrollToTop();
                                }
                              }
                            } else {
                              pushChatArr(
                                true,
                                "",
                                "未听清您说的话，请重试！",
                                "none"
                              );
                              textToSpeech("未听清您说的话，请重试");
                            }
                          }
                        })
                        .catch((err) => {
                          console.log("上传失败");
                          console.log(err);
                        });
                    }, 1000);
                  },
                  function () {
                    alert("FileWriter error!");
                  }
                );
              });
            }
          );
        } else {
          console.log("web");
        }
      } catch (e) {
        alert("stopCapture exception: " + e);
      }
    };
    const scrollToTop = () => {
      //最新消息滚动到顶部
      nextTick(() => {
        const view: any = document.querySelector("#messageList");
        if (view != null) {
          const ele: Element = view;
          ele.scrollTo({
            top: ele.scrollHeight,
          });
        }
      });
    };
    const checkSVW = (taskInfo: any) => {
      if (isSVW && taskInfo.projectId) {
        return false;
      } else return true;
    };
    //跳转任务详情
    const openTaskInfo = (task: any) => {
      router.push({
        path: "/task-page",
        query: {
          id: task.id,
          taskName: task.name,
        },
      });
    };
    //根据拼音查询人员信息 判断是否有重名的人员
    const getUserInfoByPinyin = (userName: any, callback: any) => {
      userService
        .getUserInfoByPinyin({ pinyin: userName })
        .then((res) => {
          callback(res);
        })
        .catch((ex) => {
          console.log(JSON.stringify(ex));
        });
    };
    //查询任务列表
    const getListRequest = (callback: any) => {
        console.log("searchPanelForm.value 查询参数"+JSON.stringify(searchPanelForm.value));
      taskService
        .getTaskList(searchPanelForm.value)
        .then((res: any) => {
          islist.value = false;
          callback(res);
        })
        .catch(() => {
          islist.value = false;
        });
    };
    onUnmounted(() => {
      if (animationInterval != null) {
        clearInterval(animationInterval);
      }
    });

    const clickItem = (obj: any) => {
      islist.value = true;
      if (obj) {
        selectUserId.value = obj.id;
        searchPanelForm.value.workUsers = obj.id;
        console.log("查询参数" + JSON.stringify(searchPanelForm.value));
        taskList.data = [];
        getListRequest((res: any) => {
          console.log("返回结果" + JSON.stringify(res));
          islist.value = false;
          taskList.data = res;
          let answer = "为您查询到" + taskList.data.length + "条相关信息";
          //fileUrl
          pushChatArr(true, "", answer);
        });
      }
    };

    const playAudo = (filePath: any) => {
      console.log("预览文件" + filePath);
    };
    return {
      playAudo,
      currentUserName,
      checkSVW,
      openTaskInfo,
      taskList,
      chatArr,
      searchQuestion,
      voiceText,
      touchstart,
      touchend,
      touchmove,
      repContent,
      startCapture,
      stopCapture,
      voiceStatus,
      animationIndex,
      islist,
      clickItem,
      selectUserId,
      flagCount,
      btnIconRight,
      changeIon,
    };
  },
});
